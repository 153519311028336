import axios from 'axios'

// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#request- config` for the full list of configs
const defaultOptions = {
  baseURL: process.env.REACT_APP_API_CHAT_URL,
  headers: {
    'content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
  },
  // paramsSerializer: (params) => queryString.stringify(params),
  timeout: 5000
}

// Create instance
const AxiosChatClient = axios.create(defaultOptions)

export default AxiosChatClient
