import { combineReducers } from 'redux'

// reducer import
import chatReducer from './chatReducer'

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  chat: chatReducer
})

export default reducer
