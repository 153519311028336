import { call, put } from 'redux-saga/effects'
import ACTION_TYPE from '../../../../const/ActionType'
import { getListMessagesApi } from '../../../api/chat'

export function* getListMessages({ payload, actionSuccess, actionFailed }) {
  try {
    const response = yield call(getListMessagesApi, payload)
    if (response && response.status === 200) {
      actionSuccess(response.data)
      yield put({ type: ACTION_TYPE.CHAT_GET_LIST_MESSAGES_SUCCESS, payload: response.data })
    } else {
      actionFailed(response.data)
      yield put({ type: ACTION_TYPE.CHAT_GET_LIST_MESSAGES_FAILED, payload: response.data })
    }
  } catch (error) {
    actionFailed(error)
  }
}
