import AxiosChatClient from './chatAxios'
import { API_CHAT_ENDPOINT } from '../../const/Api'

export const getListUsersApi = async () => {
  const response = await AxiosChatClient.get(API_CHAT_ENDPOINT.CHAT.LIST_USERS)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log(error)
      return error
    })
  return response
}

export const getListMessagesApi = async (payload) => {
  const response = await AxiosChatClient.get(API_CHAT_ENDPOINT.CHAT.LIST_MESSAGES, {
    params: payload
  })
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log(error)
      return error
    })
  return response
}
