import ACTION_TYPE from '../../../const/ActionType'

const initialState = {
  loading: false,
  data: null
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.CHAT_GET_LIST_MESSAGES_REQUEST:
      return {
        ...state,
        loading: true
      }
    case ACTION_TYPE.CHAT_GET_LIST_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      }
    case ACTION_TYPE.CHAT_GET_LIST_MESSAGES_FAILED:
      return {
        ...state,
        loading: false
      }
    case ACTION_TYPE.CHAT_PUSH_LIST_MESSAGES_REQUEST:
      const tmpMessages = Array.isArray(state.data) ? [...state.data] : []
      const newMessagesData = tmpMessages.concat(action.payload)
      return {
        ...state,
        data: newMessagesData
      }
    default: {
      return { ...state }
    }
  }
}

export default chatReducer
